<!--
  PACKAGE_NAME : src\pages\ai\llm\work-config
  FILE_NAME : index-config
  AUTHOR : hpmoon
  DATE : 2024-11-13
  DESCRIPTION : 운영관리 > LLM > LLM Tester > 프로젝트 작업 설정 > 질의 설정 > 인덱스 관리
-->
<template>
  <DxPopup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :close-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="closeModal"
    @shown="onShown"
  >
    <template #content>
      <div>
        <DxFileManager
          id="fileManager"
          ref="fileManager"
          :file-system-provider="fileList"
          :allowed-file-extensions="['.pdf']"
          :height="450"
          selection-mode="multiple"
          @file-uploading="onFileUploading"
          @file-uploaded="onFileUploaded"
          @item-deleting="onItemDeleting"
          @item-downloading="onFileDownloading"
          @toolbar-item-click="onToolbarItemClick"
          @context-menu-item-click="onToolbarItemClick"
        >
          <DxPermissions
            :create="false"
            :copy="false"
            :move="false"
            :delete="true"
            :rename="false"
            :upload="true"
            :download="true"
          />

          <DxItemView :show-parent-folder="false">
            <DxDetails>
              <DxColumn data-field="id" :visible="false" />
              <DxColumn data-field="thumbnail" />
              <DxColumn data-field="name" />
              <DxColumn data-field="dateModified" />
              <DxColumn data-field="size" />
            </DxDetails>
          </DxItemView>

          <DxToolbar>
            <DxItem name="upload" />
            <DxItem name="delete" />
            <DxItem name="switchView" />
          </DxToolbar>

          <DxContextMenu>
            <DxItem name="download" />
            <DxItem name="delete" />
          </DxContextMenu>
        </DxFileManager>
      </div>
    </template>
  </DxPopup>
</template>

<script>
  import { DxPopup } from 'devextreme-vue/popup';
  import { DxColumn, DxContextMenu, DxDetails, DxFileManager, DxItem, DxItemView, DxPermissions, DxToolbar } from "devextreme-vue/file-manager";

  export default {
    components: {
      DxContextMenu,
      DxPermissions, DxColumn, DxItemView, DxToolbar, DxItem, DxDetails, DxFileManager,
      DxPopup,
    },

    props: {
      isOpen: Boolean,
      project_id: String,
      indexFileList: Array,
    },

    watch: {},

    data() {
      return {
        modal: {
          title: this.$_msgContents('LLM.WORD.INDEX_CONFIG', { defaultValue: '인덱스 관리' }),
          minWidth: '900',
          width: '900',
          minHeight: '530',
          height: '530',
          dragEnabled: true,
          resizeEnabled: true,
          showCloseButton: true,
          closeOnOutsideClick: false,
        },

        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
        },

        formData: {
          project_id: null,
          name: null,
        },

        fileList: [],

        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            name: 30,
          },
        },
      };
    },

    computed: {},

    methods: {
      /** @description 파일 업로드 처리 */
      async onFileUploading(e) {
        const formData = new FormData();
        formData.append('project_id', this.project_id);
        formData.append('knowledge', e.fileData, e.fileData.name);

        const payload = {
          actionname: 'LLM_INDEX_FILE_EXISTS',
          data: {
            project_id: this.project_id,
            name: e.fileData.name,
          },
          loading: true,
        };

        const payload2 = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          actionname: 'LLM_INDEX_FILE_INSERT',
          data: formData,
          loading: true,
        };

        e.cancel = new Promise((resolve) => {
          this.CALL_LLM_API(payload)
            .then(result => {
              if (result.status === 200) {
                if (!result.data) {
                  this.CALL_LLM_API(payload2)
                    .then(result => {
                      if (result.status === 200) {
                        resolve({ cancel: false });
                        e.fileData.id = result.data.knowledge_id;
                      } else {
                        resolve({ cancel: true });
                        this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
                      }
                    });
                } else {
                  resolve({ cancel: true });
                  this.$_Msg(this.$_msgContents('LLM.MESSAGE.DUPLICATE_INDEX_FILE', { defaultValue: '이미 중복된 파일이 등록되어 있습니다.' }));
                }
              } else {
                resolve({ cancel: true });
                this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
              }
            });
        });
      },

      /** @description 파일 업로드 처리 후 */
      onFileUploaded(e) {
        this.fileList.find(file => {
          if (file.name === e.fileData.name) {
            file.id = e.fileData.id;
          }
        });
        this.$emit('updateFileList', this.fileList);
        this.$_Toast(this.$_msgContents('CMN_SUC_UPLOAD'));
      },

      /** @description 파일 삭제 */
      onItemDeleting(e) {
        const payload = {
          actionname: 'LLM_INDEX_FILE_DELETE',
          data: e.item.dataItem,
          loading: true,
        };

        e.cancel = new Promise((resolve) => {
          this.CALL_LLM_API(payload)
            .then(result => {
              if (result.status === 200) {
                resolve({ cancel: false });
                this.$emit('updateFileList', this.fileList);
                this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
              } else {
                resolve({ cancel: true });
                this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
              }
            });
        });
      },

      /** @description 파일 다운로드 */
      async onFileDownloading(e) {
        e.cancel = true;

        const payload = {
          actionname: 'LLM_INDEX_FILE_DOWNLOAD',
          data: {
            id: e.item.dataItem.id
          },
          responseType: 'blob',
          loading: true,
        }
        const res = await this.CALL_LLM_API(payload);
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'application/pdf' });

          const reader = new FileReader();
          reader.onload = function () {
            const downloadLink = reader.result.toString();
            const link = document.createElement('a');
            link.href = downloadLink;
            link.setAttribute('download', e.item.dataItem.name);
            link.click();
          };
          reader.readAsDataURL(blob);
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
        }
      },

      /** @description 툴바 아이템 클릭 이벤트 */
      async onToolbarItemClick(e) {
        this.$nextTick(() => {
          if (e.itemData === "delete" || e.itemData.name === "delete") {
            document.querySelector('div.dx-filemanager-dialog-delete-item > div').innerText =
              `${ this.$_msgContents('LLM.MESSAGE.DELETE_INDEX_FILE_ALERT1', { defaultValue: '인덱스 파일 삭제 시, 질의에 사용된 근거 문서도 함께 삭제 됩니다.' }) }
               ${ this.$_msgContents('LLM.MESSAGE.DELETE_INDEX_FILE_ALERT2', { value: e.component.getSelectedItems().length }) }`
          }
        });
      },

      /** @description 모달 닫기 */
      closeModal() {
        this.$emit('closeModal');
      },

      /** @description 팝업이 다 보여진 후 처리 */
      onShown() {
        // 팝업이 다 보여지기 전에 처리되면 팝업이 두번 열리는것 처럼 처리됨
        this.fileList = this.$_commonlib.cloneObj(this.indexFileList);
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.formData.project_id = this.project_id;
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {

    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dx-drawer-panel-content.dx-drawer-panel-content-initial,
    .dx-filemanager .dx-filemanager-breadcrumbs,
    #fileManager .dx-toolbar-after > :nth-child(2).dx-item.dx-toolbar-item.dx-toolbar-button,
    #fileManager .dx-toolbar-after > :nth-child(3).dx-item.dx-toolbar-item.dx-toolbar-button,
    #fileManager .dx-overlay-content.dx-popup-normal.dx-resizable.dx-popup-inherit-height {
      display: none !important;
    }

    .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td {
      border-top: 1px solid #e5f7f9;
      border-bottom: 1px solid #e5f7f9;
    }

    .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused) {
      background-color: #e5f7f9;
      color: unset;
    }

    .dx-filemanager .dx-filemanager-files-view.dx-filemanager-details .dx-row-focused .dx-filemanager-file-actions-button .dx-button .dx-icon {
      color: unset;
    }

    .dx-filemanager .dx-filemanager-toolbar.dx-filemanager-file-toolbar,
    .dx-filemanager .dx-filemanager-toolbar.dx-filemanager-file-toolbar .dx-toolbar {
      background: unset;
    }

    .dx-filemanager-toolbar {
      min-height: unset;
    }
  }
</style>